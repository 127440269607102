import React from "react";
import { MainContainer, ProductTitle } from "./WebHook.element";

function WebHook() {
  return (
    <MainContainer>
      <ProductTitle>This is the webHooks page</ProductTitle>
    </MainContainer>
  );
}

export default WebHook;
